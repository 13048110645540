import MSG from 'consts/Messages'
import { toast } from 'react-toastify'

const getRequestOptions = (body, method = 'POST', redirect = 'follow', accessToken = '') => {
  if (!body || !method || !redirect) {
    toast.error(MSG.ERR.REQUEST_OPTION_ERR, { toastId: 'operation-error' })

    return
  }

  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  accessToken && myHeaders.append('Authorization', `Bearer ${accessToken}`)

  const requestOptions = {
    method,
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect,
  }

  return requestOptions
}

export default getRequestOptions

// export const PRIMARY_PAYMENT_PROVIDER = 'stripe'
export const PRIMARY_PAYMENT_PROVIDER = 'ryftpay'

export const RYFT_BASE_URL = process.env.NEXT_PUBLIC_APP_ENV
  ? 'https://sandbox-api.ryftpay.com/v1'
  : 'https://api.ryftpay.com/v1'

export const PAYMENT_SESSION = `${RYFT_BASE_URL}/payment-session`

const PROCESSOR_CONFIG = {
  ryftpay: {
    name: 'ryftpay',
    feePercent: 1,
    feeFixed: 0,
  },
  stripe: {
    name: 'stripe',
    feePercent: 1.5,
    feeFixed: 20,
  },
}

export const MAX_PAYMENT_THRESHOLD_GBP = 10000

export const DEFAULT_PAYMENT_PROCESSOR_CONFIG = PROCESSOR_CONFIG[PRIMARY_PAYMENT_PROVIDER]

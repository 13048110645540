import MSG from 'consts/Messages'
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { sendSlackNotification } from 'services/client/SlackNotification'
import { v4 as uuidv4 } from 'uuid'

// -----------------------------------------------------------------------------
export * from './orders'
export * from './events'
// -----------------------------------------------------------------------------

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const submitDeleteRequestToDB = async (
  feedbackMessage,
  user,
  setFeedbackMessage,
  setSubmitted
) => {
  try {
    const docRef = doc(db, 'deleteRequests', uuidv4())

    const payload = {
      message: feedbackMessage,
      user: user?.email,
      name: user?.firstName + ' ' + user?.lastName,
      userId: user?.id,
    }

    await sendSlackNotification(payload, 'Delete Account Request')

    await setDoc(docRef, payload)

    // Reset state after successful submission
    setFeedbackMessage('')
    setSubmitted(true)
    return true
  } catch (error) {
    toast.error('Error submitting delete request', error)
    return false
  }
}

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const getUserByIdwRTU = async (userId, callback = () => {}) => {
  if (!userId) return

  const userInfoQuery = query(collection(db, 'users'), where('id', '==', userId))

  try {
    onSnapshot(userInfoQuery, snapshot => {
      const user = snapshot.docs.map(doc => doc.data())[0]
      callback(user)
    })
  } catch (error) {
    toast.error('Error @getUserInfoSS', error)
  }
}

export const getUserByEmail = async email => {
  if (!email) return

  const q = query(collection(db, 'users'), where('email', '==', email))

  try {
    const querySnapshot = await getDocs(q)
    const retrievedUser = querySnapshot.docs.map(doc => doc.data())
    return retrievedUser?.length > 0 ? retrievedUser[0] : []
  } catch (error) {
    console.error('Error @getUserByEmail', error)
    toast.error(error.message)
    return []
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################
export const updateUserCheckIns = async (user, event, scanner, ticket) => {
  try {
    const payload = {
      ticketId: ticket.id,
      eventId: event?.eventId,
      checkInTime: new Date(),
      scannedBy: {
        id: scanner?.id,
        firstName: scanner?.firstName,
        lastName: scanner?.lastName,
        email: scanner?.email,
      },
    }

    const userDocRef = doc(db, 'users', user.id)
    await updateDoc(userDocRef, {
      checkIns: arrayUnion(payload),
    })
  } catch (error) {
    console.error('Error @updateOrderCheckedInStatus', error)
    toast.error(error.message)
  }
}

export const updateUser = async (userId, payload) => {
  if (!userId || !payload) return

  try {
    const docRef = doc(db, 'users', userId)
    await updateDoc(docRef, payload)
  } catch (error) {
    console.error('Error updating user profile in Firebase', error)
    throw error
  }
}

export const updateEmailPreferencesToDB = async (
  user,
  acceptsGeneralEmail,
  acceptsVenuesEmail,
  emailPreference,
  hostSummaryPreference,
  notifyIndividualEventSummary,
  notifyMonthlyEventSummaries,
  callBack,
  loading = {
    start: () => {},
    remove: () => {},
  }
) => {
  try {
    const payload = {
      marketingEmailPreferences: {
        general: acceptsGeneralEmail,
        venues: acceptsVenuesEmail,
      },
      notificationEmailPreferences: {
        hosting: {
          individualEventSummaries: notifyIndividualEventSummary,
          monthlyEventSummaries: notifyMonthlyEventSummaries,
        },
      },
    }

    // Trigger the update function if there is a change in email preferences
    if (
      !(
        acceptsGeneralEmail === emailPreference?.general &&
        acceptsVenuesEmail === emailPreference?.venues
      )
    ) {
      loading.start()

      const docRef = doc(db, 'users', user?.id)
      await updateDoc(docRef, payload)

      // Fetch updated user document -> The callback coming from parent will run!
      callBack()

      toast.success(MSG.SUCCESS.BUYER.EMAIL_PREFERENCE_UPD, { toastId: 'email-pref-updated' })
    }

    // Trigger the update function if there is a change in host summary preferences
    if (
      !(
        notifyIndividualEventSummary === hostSummaryPreference?.individualEventSummaries &&
        notifyMonthlyEventSummaries === hostSummaryPreference?.monthlyEventSummaries
      )
    ) {
      loading.start()

      const docRef = doc(db, 'users', user?.id)
      await updateDoc(docRef, payload)

      // Fetch updated user document -> The callback coming from parent will run!
      callBack()

      toast.success(MSG.SUCCESS.BUYER.EMAIL_PREFERENCE_UPD, { toastId: 'email-pref-updated' })
    }
  } catch (error) {
    console.error('Error updating email preferences', error)
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'operation-failed' })
  } finally {
    loading.remove()
  }
}

export const addUserToEventWaitlist = async (event, user, callback) => {
  try {
    if (!event || !user || !event?.eventId) throw new Error('Missing event or user data!')

    const eventRef = doc(db, 'events', event?.eventId)
    const userRef = doc(db, 'users', user?.id)

    const payload = {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      id: user?.id,
      joindWaitlist: new Date(),
    }

    // Add user to event's waitlist
    await updateDoc(eventRef, { waitList: arrayUnion(payload) })

    // Update user's waitlistRequest status
    await updateDoc(userRef, { waitlistRequest: true })

    callback()
  } catch (error) {
    console.error('Error adding to waitlist', error)
    toast.error(MSG.ERROR.OPERATION_FAILED)
  }
}

export const removeUserFromEventWaitlist = async (
  eventId,
  user,
  userWaitListObj,
  callback,
  loading = {
    start: () => {},
    remove: () => {},
  }
) => {
  try {
    loading.start()
    const eventRef = doc(db, 'events', eventId)
    const userRef = doc(db, 'users', user?.id)

    // Remove user from event's waitlist
    await updateDoc(eventRef, { waitList: arrayRemove(userWaitListObj) })

    // Update user's waitlistRequest status
    await updateDoc(userRef, { waitlistRequest: false })

    callback()

    toast.warn(MSG.WARNING.SELLER.WAITLIST_REMOVED, { toastId: 'delisted' })
  } catch (error) {
    console.error('Error removing from waitlist', error)
    toast.error(MSG.ERROR.OPERATION_FAILED)
  } finally {
    loading.remove()
  }
}

export const addReferralSource = async (user, newSourceObj) => {
  try {
    const docRef = doc(db, 'users', user.id)

    await updateDoc(docRef, { referralSources: arrayUnion(newSourceObj) })
  } catch (error) {
    toast.error('Error adding source!', error)
  }
}

export const updateReferralSource = async (user, referralSources = []) => {
  try {
    const docRef = doc(db, 'users', user.id)

    await updateDoc(docRef, { referralSources })
  } catch (error) {
    toast.error('Error adding source!', error)
  }
}

// #############################################################################
// #                                DELETE                                     #
// #############################################################################

// ------------- UI Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { DEF_QUERY_PARAMS } from 'consts/QueryParams'
import { HYDRATE } from 'next-redux-wrapper'

const defaultSort = {
  id: '',
  desc: {},
}
const todayDate = new Date()
const defaultOrderSelection = {
  name: false,
}

const defaultColumnSelection = {
  revenue: false,
  summaryFees: false,
}

const defaultTicketsColumnSelection = {
  purchasedOn: false,
  eventType: false,
  isPrivate: false,
  hostEmail: false,
  hostName: false,
  hostPhone: false,
  hostImage: false,
  isReserved: false,
  isCancelled: false,
  freeTicketCount: false,
  paidTicketCount: false,
}

// #1 Initial state
const initialState = {
  viewedAlertsTimeStamps: [],
  activeNav: 'Home',
  isDrawerOpen: false,
  onboarding: false,
  titleBar: {},
  megaMenu: false,
  pwaRoute: 'home',
  // userBackChevron: null,
  showDebug: false,
  signUp: false,
  platform: 'unknown',
  appUI: false,
  searchQuery: '',
  theme: 'light',
  notifications: [{}],
  visitorIP: '',
  visitorIPCountry: 'GB',
  exchange: {},
  exchangeTimeStamp: new Date().setHours(new Date().getHours() - 2),
  basketStore: {},
  ticketStore: null,
  isScannerForCurrentEventPage: false,
  marketingEmail: {},
  queryParams: DEF_QUERY_PARAMS,
  scrollPosition: 0,
  activeModalName: '',
  pricingModalDetails: {},
  wantsToJoinWaitList: false,
  table: [
    {
      EventList: {
        search: '',
        sort: [{ ...defaultSort }],
        range: 'future',
        column: {
          ...defaultColumnSelection,
        },
      },
    },
    {
      MasterGuestList: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {},
      },
    },
    {
      EventWaitList: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {},
      },
    },
    {
      RecentOrder: {
        search: '',
        sort: [{ ...defaultOrderSelection }],
        column: {},
      },
    },
    {
      TicketList: {
        search: '',
        sort: [{ ...defaultSort }],
        column: { ...defaultTicketsColumnSelection },
      },
    },
    {
      Venue: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {},
      },
    },
  ],

  isCookieSet: false,
  cookie: {
    necessary: true,
    performance: false,
    statistics: false,
  },
  charts: {
    salesByDate: {
      viewChartOf: 'Ticket Sales',
      date: [
        {
          startDate: todayDate.setDate(todayDate.getDate() - 7),
          endDate: new Date(),
          key: 'selection',
        },
      ],
    },
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    markAlertsAsViewed: (state, action) => {
      // Make sure that we don't again store the saved timestamp if the state already has it! (This won't ever happen via the UI, but preventing here for the shake of completeness!)
      if (state?.viewedAlertsTimeStamps?.includes(action?.payload)) return state
      return {
        ...state,
        viewedAlertsTimeStamps: [...(state?.viewedAlertsTimeStamps ?? ''), action?.payload],
      }
    },
    setChartsSelection: (state, action) => ({ ...state, charts: action?.payload }),
    setActiveNav: (state, action) => ({ ...state, activeNav: action?.payload || '' }),
    setCookie: (state, action) => ({
      ...state,
      cookie: action?.payload,
    }),
    setTableFilters: (state, action) => {
      const { tableName, userPreference } = action.payload
      const listIndex = state.table.findIndex(item => Object.keys(item)[0] === tableName)

      if (listIndex !== -1) {
        state.table[listIndex][tableName] = userPreference
      }
    },
    setDrawer: (state, action) => ({ ...state, isDrawerOpen: action?.payload }),
    setOnboarding: (state, action) => ({ ...state, onboarding: action?.payload }),
    setIsCookieSet: (state, action) => ({ ...state, isCookieSet: action?.payload }),
    setTitleBar: (state, action) => ({ ...state, titleBar: action?.payload }),
    setShowDebug: (state, action) => ({ ...state, showDebug: action?.payload }),
    setPwaRoute: (state, action) => ({ ...state, pwaRoute: action?.payload }),
    setMegaMenu: (state, action) => ({ ...state, megaMenu: action?.payload }),
    setSignUp: (state, action) => ({ ...state, signUp: action?.payload }),

    setPlatform: (state, action) => ({ ...state, platform: action?.payload }),
    setAppUI: (state, action) => ({ ...state, appUI: action?.payload }),

    setSearchQuery: (state, action) => ({ ...state, searchQuery: action?.payload }),
    setTheme: (state, action) => ({ ...state, theme: action?.payload }),
    setNotifications: (state, action) => ({ ...state, notifications: action?.payload }),

    setVisitorIPCountry: (state, action) => ({ ...state, visitorIPCountry: action?.payload }),
    setVisitorIP: (state, action) => ({ ...state, visitorIP: action?.payload }),
    setExchange: (state, action) => ({ ...state, exchange: action?.payload }),

    setBasketStore: (state, action) => {
      const eventId = action?.payload?.eventId || ''
      const basketStore = action?.payload?.basketStore || {}

      return { ...state, basketStore: { ...state?.basketStore, [eventId]: basketStore } }
    },

    setTicketStore: (state, action) => ({ ...state, ticketStore: action?.payload }),
    setScrollPosition: (state, action) => ({ ...state, scrollPosition: action?.payload }),
    setMarketingEmail: (state, action) => ({ ...state, marketingEmail: action?.payload }),
    setIsScannerForCurrentEventPage: (state, action) => ({
      ...state,
      isScannerForCurrentEventPage: action?.payload,
    }),
    setQueryParams: (state, action) => ({ ...state, queryParams: action?.payload }),
    setActiveModalName: (state, action) => ({ ...state, activeModalName: action?.payload }),
    setPricingModalDetails: (state, action) => ({ ...state, pricingModalDetails: action?.payload }),
    setWantsToJoinWaitList: (state, action) => ({ ...state, wantsToJoinWaitList: action?.payload }),

    resetUI: () => initialState,
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  markAlertsAsViewed,
  setActiveNav,
  setChartsSelection,
  setDrawer,
  setOnboarding,
  setTitleBar,
  setPlatform,
  setAppUI,
  setPwaRoute,
  setShowDebug,
  setMarketingEmail,
  resetUI,
  setMegaMenu,
  setTheme,
  setIsCookieSet,
  setSignUp,
  setCookie,
  setScrollPosition,
  setSearchQuery,
  setNotifications,
  setVisitorIPCountry,
  setVisitorIP,
  setExchange,
  setExchangeTimeStamp,
  setBasketStore,
  setTicketStore,
  setIsScannerForCurrentEventPage,
  setQueryParams,
  setActiveModalName,
  setPricingModalDetails,
  toggleViewingMode,
  setWantsToJoinWaitList,
  setTableFilters,
} = uiSlice.actions

export default uiSlice.reducer
